<template>
    <div class="tab-pane"
        :id="id"
        role="tabpanel"
        :aria-labelledby="id + '-tab'">
        <slot></slot>
    </div>
</template>

<script lang="ts" setup>
import { inject, onMounted, onUnmounted, toRefs } from 'vue';
import { key } from './injection-keys';

const props = defineProps({
    id: { type: String, required: true },
    title: { type: String, required: true }
})

const { id, title } = toRefs(props);

const tabsContext = inject(key);

onMounted(() => {
    if (tabsContext) {
        tabsContext.registerTabPane(id, title)
    }
})

onUnmounted(() => {
    tabsContext?.unregisterTabPane(id.value);
})
</script>