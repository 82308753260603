import { MaybeRef } from "@vueuse/core";
import { InjectionKey, Ref } from "vue";

export interface TabPaneProps {
    id: Ref<string>;
    title: Ref<string>;
}

interface TabsSharedContext {
    registerTabPane: (id: MaybeRef<string>, title: MaybeRef<string>) => void;
    unregisterTabPane: (id: string) => void;
}

export const key = Symbol() as InjectionKey<TabsSharedContext>;