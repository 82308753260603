<template>
    <ul v-bind="$attrs"
        ref="navTabsRef"
        class="nav nav-tabs"
        :class="navTabsClassList"
        role="tablist">
        <li v-for="item in navTabs"
            class="nav-item"
            role="presentation">
            <button
                class="nav-link"
                :class="navTabsBtnClass"
                :id="item.id + '-tab'"
                data-bs-toggle="tab"
                :data-bs-target="'#' + item.id"
                type="button"
                role="tab"
                :aria-controls="item.id"
                aria-selected="false">
                {{ item.title }}
            </button>
        </li>
    </ul>
    <div class="tab-content" :class="{ 'tab-content-full-height': fullHeight }">
        <slot></slot>
    </div>
</template>

<script lang="ts" setup>
import Tab from 'bootstrap/js/dist/tab';
import { onBeforeUnmount, ref, provide, nextTick, Ref, reactive, computed } from 'vue';
import { key, TabPaneProps } from './injection-keys';

const props = defineProps({
    navTabsClass: String,
    navTabsBtnClass: String,
    fullHeight: Boolean,
    defaultActiveIndex: {
        type: Number,
        default: 0
    }
})

const navTabsClassList = computed(() => {
    const classList: string[] = [];
    if (props.navTabsClass) {
        classList.push(props.navTabsClass)
    }
    if (props.fullHeight) {
        classList.push('flex-shrink-0');
    }
    return classList;
})

const navTabs = ref<Array<TabPaneProps>>([]);

const navTabsRef = ref<Element>();
let tabInstances: Array<Tab> = [];

const registerTabPane = (id: Ref<string>, title: Ref<string>) => {
    navTabs.value.push(reactive({ id, title }));

    nextTick(() => {
        const triggerEl = navTabsRef.value!.querySelector(`[data-bs-target="#${id.value}"]`);
        if (!triggerEl) return;
    
        const newTabInstance = new Tab(triggerEl);
        tabInstances.push(newTabInstance);

        const index = tabInstances.indexOf(newTabInstance);
    
        if (index === 0) newTabInstance.show();
    })
}

const unregisterTabPane = (id: string) => {
    const navTabIdx = navTabs.value.findIndex(x => x.id === id);
    if (navTabIdx === -1) return;

    navTabs.value.splice(navTabIdx, 1);

    const existingTabInstance = tabInstances.splice(navTabIdx, 1);
    existingTabInstance.forEach(x => x.dispose())
}

onBeforeUnmount(() => {
    tabInstances.forEach(x => x.dispose())
})

provide(key, { registerTabPane, unregisterTabPane })

</script>
